import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 3";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "module-4"
    }}>{`Module 4`}</h1>
    <p>{`This final module shows some finishing touches on the example app, adds Stripe payments, live re-deploys with Zeit, and talks about going to production with a split between dev and prod backends so you don't overwrite your data.`}</p>
    <h2 {...{
      "id": "where-were-at"
    }}>{`Where we're at`}</h2>
    <Vimeo id="396353090" mdxType="Vimeo" />
    <h2 {...{
      "id": "what-were-doing-next"
    }}>{`What we're doing next`}</h2>
    <Vimeo id="396353135" mdxType="Vimeo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      